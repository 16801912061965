import {
  getOwner, getOwners, addOwner, updateOwner, getSimilarOwners, bulkAssignManagers,
} from 'actions/owners';

const owners = () => {
  const initialState = {
    owner: {},
    owners: [],
    ownerCreated: {},
    ownerUpdated: {},
    similarOwners: [],
    isSuccessful: false,
    bulkAssignSuccess: false,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getOwner.requestTypes.SUCCESS:
        return {
          ...state,
          owner: response,
          isSuccessful: true,
          bulkAssignSuccess: false,
        };
      case getOwners.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case getOwners.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case getOwners.requestTypes.SUCCESS:
        return {
          ...state,
          owners: response,
          isSuccessful: true,
          bulkAssignSuccess: false,
        };
      case addOwner.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case addOwner.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case addOwner.requestTypes.SUCCESS:
        return {
          ...state,
          ownerCreated: response,
          isSuccessful: true,
          bulkAssignSuccess: false,
        };
      case updateOwner.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case updateOwner.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case updateOwner.requestTypes.SUCCESS:
        return {
          ...state,
          ownerUpdated: response,
          isSuccessful: true,
          bulkAssignSuccess: false,
        };
      case getSimilarOwners.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case getSimilarOwners.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
          bulkAssignSuccess: false,
        };
      case getSimilarOwners.requestTypes.SUCCESS:
        return {
          ...state,
          similarOwners: response,
          isSuccessful: true,
          bulkAssignSuccess: false,
        };
      case bulkAssignManagers.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
          bulkAssignSuccess: true,
        };
      default:
        return state;
    }
  };
};

export default owners();
